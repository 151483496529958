<template>
  <div>
    <div id="component">
      <CSTabBar :tabbar="tabBarList" :checkedTab="assessmentType" @changeTabBar="changeAssessmentType"></CSTabBar>
      <div class="mt-3">
        <div
            class="btn-group"
            v-if="staffInfo.jobLevel == 1"
        >
          <template
              v-if="
                            ![1001, 1002].includes(
                                Number(staffInfo.dutyType)
                            ) ||
                            (assessmentType == 2 &&
                                1001 == Number(staffInfo.dutyType))
                        "
          >
            <button
                type="button"
                style="width: 150px"
                @click="assessmentObjectType = 1"
                :class="[
                                'btn',
                                'btn-lg',
                                'btn-secondary',
                                {
                                    'btn-primary': assessmentObjectType == 1,
                                },
                            ]"
            >
              普通员工
            </button>
          </template>

          <template
              v-if="
                            1002 == Number(staffInfo.dutyType) ||
                            (1001 == Number(staffInfo.dutyType) &&
                                assessmentType == 2)
                        "
          >
            <button
                type="button"
                style="width: 150px"
                @click="assessmentObjectType = 2"
                :class="[
                                'btn',
                                'btn-lg',
                                'btn-secondary',
                                {
                                    'btn-primary': assessmentObjectType == 2,
                                },
                            ]"
            >
              部门主管
            </button>
          </template>
          <template v-if="1001 == Number(staffInfo.dutyType)">
            <button
                type="button"
                style="width: 150px"
                @click="assessmentObjectType = 3"
                :class="[
                                'btn',
                                'btn-lg',
                                'btn-secondary',
                                {
                                    'btn-primary': assessmentObjectType == 3,
                                },
                            ]"
            >
              物业经理
            </button>
          </template>
        </div>
      </div>

      <template v-if="[1001, 1002].includes(Number(staffInfo.dutyType))">
        <div class="filter-panel" style="margin-top: 20px">
          <CSSelect style="margin-right: 30px;">
            <select v-model="queryParams.checkedOrg" style="padding-left: 5px;">
              <option value="">请选择</option>
              <option
                  v-for="department in orgList"
                  :key="department.code"
                  :value="department"
              >
                {{ department.name }}
              </option>
            </select>
          </CSSelect>
          <button
              class="btn btn-primary"
              @click="queryData"
          >
            查询
          </button>
        </div>
      </template>


      <div class="result-panel">
        <CSTable v-if="assessmentType === ASSESSMENT_TYPE.COMMON"
                 :thead-top="[1001, 1002].includes(Number(staffInfo.dutyType)) ? 60 : 0">
          <template v-slot:header>
            <div class="table-header-panel text-right">
              <button
                  type="button"
                  class="btn btn-primary btn-sm sticky-right"
                  @click="openAddCommonTable()"
              >
                <svg
                    class="icon"
                    aria-hidden="true"
                >
                  <use xlink:href="#icon-menujiahao"></use>
                </svg>
                添加考核表
              </button>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <th class="text-center">表名</th>
              <th class="text-center">部门</th>
              <th class="text-center">考核项</th>
              <th class="text-center">通知提醒</th>
              <th class="text-center">操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <template
                v-for="(as, index) in assessmentTableData"
            >
              <tr :key="index">
                <td class="text-center">
                  {{ as.name }}
                </td>
                <td class="text-center">
                  {{ as.orgName }}
                </td>
                <td class="text-center">
                                            <span class="allow-click" @click="lookCommonAssessmentItem(as)">
                                                {{ as.items.length }}
                                                <svg
                                                    :class="['icon', {reversal: assessmentItemOpenStatus[as.id] && as.items && as.items.length > 0}]"
                                                    aria-hidden="true">
                                                    <use xlink:href="#icon-menuxiala"></use>
                                                </svg>
                                            </span>
                </td>
                <td class="text-center">
                  {{
                    as.disposable == 0
                        ? "单次"
                        : "循环"
                  }}/{{ as.scheduleDays }}天
                </td>
                <td class="text-center">
                  <div
                      class="btn-group"
                  >
                    <button
                        type="button"
                        class="btn btn-primary dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                      操作
                    </button>
                    <ul
                        class="dropdown-menu"
                        style="min-width: 76px; width: 100px;"
                    >
                      <li
                          @click="openCommonAssessmentItem(as)"
                      >
                        <a
                            style="width: 100%;"
                        >添加考核项</a>
                      </li>
                      <li
                          @click="openAddCommonTable(as)"
                      >
                        <a
                            style="width: 100%;"
                        >修改</a>
                      </li>
                      <li
                          @click="deleteAssessmentTable(as)"
                      >
                        <a
                            style="width: 100%;"
                        >删除</a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <template
                  v-if="
                                            assessmentItemOpenStatus[as.id] &&
                                            as.items &&
                                            as.items.length > 0
                                        "
              >
                <tr :key="index + '_2'">
                  <td
                      colspan="5"
                      style="
                                                    background: #e6e4e4;
                                                    overflow-y: auto;
                                                "
                  >
                    <table>
                      <thead>
                      <tr>
                        <th
                            class="text-center"
                        >
                          考核项
                        </th>
                        <th
                            class="text-center"
                        >
                          分值
                        </th>
                        <th
                            class="text-center"
                        >
                          操作
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          v-for="(
                                                                item, itemIndex
                                                            ) in as.items"
                          :key="
                                                                itemIndex +
                                                                '-' +
                                                                itemIndex
                                                            "
                      >
                        <td
                            class="text-center"
                        >
                          {{
                            item.content ||
                            '-'
                          }}
                        </td>
                        <td
                            class="text-center"
                        >
                          {{ item.score }}
                        </td>
                        <td
                            class="text-center"
                        >
                          <div
                              class="btn-group"
                          >
                            <button
                                type="button"
                                class="btn btn-primary dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                              操作
                            </button>
                            <ul
                                class="dropdown-menu"
                                style="min-width: 76px; width: 76px;"
                            >
                              <li
                                  @click="openCommonAssessmentItem(as,item)"
                              >
                                <a
                                    style="width: 100%;"
                                >修改</a>
                              </li>
                              <li
                                  @click="deleteCommonItem(as,item)"
                              >
                                <a
                                    style="width: 100%;"
                                >删除</a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </template>
            </template>
          </template>
        </CSTable>
        <CSTable v-else :thead-top="[1001, 1002].includes(Number(staffInfo.dutyType)) ? 60 : 0">
          <template v-slot:header>
            <div class="table-header-panel text-right">
              <button
                  type="button"
                  class="btn btn-primary btn-sm sticky-right"
                  @click="openBusinessAssessmentItem()"
              >
                <svg
                    class="icon"
                    aria-hidden="true"
                >
                  <use xlink:href="#icon-menujiahao"></use>
                </svg>
                添加考核项
              </button>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <th class="text-center">考核项</th>
              <th class="text-center">扣分分数</th>
              <th class="text-center">操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr
                v-for="(item, itIndex) in businessItemData"
                :key="itIndex + 'tr'"
            >
              <td class="text-center">
                {{ item.content }}
              </td>
              <td class="text-center">
                {{ item.score }}
              </td>
              <td class="text-center">
                <div
                    class="btn-group"
                >
                  <button
                      type="button"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                  >
                    操作
                  </button>
                  <ul
                      class="dropdown-menu"
                      style="min-width: 76px; width: 76px;"
                  >
                    <li
                        @click="openBusinessAssessmentItem(item)"
                    >
                      <a
                          style="width: 100%;"
                      >修改</a>
                    </li>
                    <li
                        @click="deleteBusinessItem(item)"
                    >
                      <a
                          style="width: 100%;"
                      >删除</a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </template>
        </CSTable>
        <Pagination/>
      </div>
      <!--  添加通用考核表  -->
      <AddCommonTable
          :org-list="orgList"
          name="addCommonTable"
          componentName="AddCommonTable"
          :department="queryParams.checkedOrg.dutyType"
      ></AddCommonTable>
      <!-- 删除弹窗 -->
      <DeleteModal
          :delete-type="deleteType"
          :delete-info="deleteInfo"
          ref="deleteModal"
      ></DeleteModal>
      <!-- 添加/编辑考核项   -->
      <EditAssessmentItem
          :org-list="orgList"
          :target="assessmentObjectType"
          :department="queryParams.checkedOrg.dutyType"
          :assessment-item-type="assessmentItemType"
          ref="editAssessmentItem"
      ></EditAssessmentItem>
    </div>
  </div>
</template>
<script>
import DeleteModal from "@/components/DeleteModal.vue";
import EditAssessmentItem from "@/components/EditAssessmentItem.vue";
import AddCommonTable from "@/components/AddCommonTable.vue";
import CSSelect from "@/components/common/CSSelect";
import Pagination from "@/components/Pagination";
import {
  listProfessionalItemUrl,
  listGeneralFormUrl,
  queryDepartmentUrl,
} from "@/requestUrl";
import {ORG_LEVEL} from "@/constant";
import CSTabBar from "@/components/common/CSTabBar";
import CSTable from "@/components/common/CSTable";

// 考核类型
const ASSESSMENT_TYPE = {
  BUSINESS: 2,
  COMMON: 1,
  SYSTEM: 3,
};

// 删除时的类型
const DELETE_TYPE = {
  TABLE: 1, // 删除通用考核表
  BUSINESS_ITEM: 2, // 删除业务考核项
  COMMON_ITEM: 3, // 删除通用考核项
};
// 考核人员
const ASSESSMENT_OBJECT_TYPE = {
  STAFF: 1,
  SUPERVISOR: 2,
  MANAGER: 3,
};

const AUTO_ASSESSMENT_TYPE = {
  INSPECTION: 1,
  WORK_ORDER: 2,
  ATTENDANCE: 3,
};

// 考核项目
const ASSESSMENT_ITEM_TYPE = {
  COMMON_ITEM: 1,
  BUSINESS_ITEM: 2,
};

const DEFAULT_PAGE = 1;
const DEFAULT_ROWS = 10;

export default {
  props: {},
  components: {
    CSTable,
    DeleteModal,
    EditAssessmentItem,
    AddCommonTable,
    CSSelect,
    CSTabBar,
    Pagination,
  },
  data() {
    return {
      deleteType: null,
      ASSESSMENT_TYPE,
      deleteInfo: null,
      assessmentItemType: null,
      assessmentType: ASSESSMENT_TYPE.BUSINESS,
      assessmentObjectType: ASSESSMENT_OBJECT_TYPE.STAFF,
      assessmentTableData: [], // 通用考核表
      queryParams: {
        checkedOrg: "",
      },
      assessmentItemOpenStatus: {},
      businessItemData: [], // 业务考核项
      staffInfo: this.$vc.getCurrentStaffInfo(),
      orgList: [],
      tabBarList: {
        [ASSESSMENT_TYPE.BUSINESS]: "业务考核",
        [ASSESSMENT_TYPE.COMMON]: "通用考核"
      },
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  mounted() {
    this.$vc.on(this.$route.path, "assessmentManage", "list", () => {
      this.queryData();
    });
    this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
      this.pageParams.pageNo = pageNo;
      if (this.assessmentType == ASSESSMENT_TYPE.BUSINESS) {
        this.getBusinessAssessmentItem(pageNo);
      } else {
        this.getAssessmentTable(pageNo);
      }
    })
    this.initAssessmentObjectType();
    this.getOrgList((orgList) => {
      const staffInfo = this.$vc.getCurrentStaffInfo();
      orgList.forEach((val) => {
        if (val.code === staffInfo.departmentCode) {
          this.$set(this.queryParams, "checkedOrg", val);
        }
      });
      if (this.assessmentType === ASSESSMENT_TYPE.COMMON) {
        this.getAssessmentTable();
      } else {
        this.getBusinessAssessmentItem();
      }
    });
  },
  methods: {
    // 获取部门列表
    getOrgList(handler) {
      this.$fly
          .post(queryDepartmentUrl, {
            regionCode: this.$vc.getCurrentRegion().code,
            code: "",
            name: "",
            dutyType: "",
            parentCode: "",
            level: ORG_LEVEL.DEPARTMENT,
            pageNo: 1,
            pageSize: 50,
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            this.orgList = res.data;
            handler(res.data);
          });
    },
    initAssessmentObjectType() {
      const staffInfo = this.$vc.getCurrentStaffInfo();
      if (![1001, 1002].includes(Number(staffInfo.dutyType))) {
        this.assessmentObjectType = ASSESSMENT_OBJECT_TYPE.STAFF;
      }
      if (Number(staffInfo.dutyType) === 1002) {
        this.assessmentObjectType = ASSESSMENT_OBJECT_TYPE.SUPERVISOR;
      }
      if (Number(staffInfo.dutyType) === 1001) {
        this.assessmentObjectType = ASSESSMENT_OBJECT_TYPE.MANAGER;
      }
    },
    changeAssessmentType(type) {
      const staffInfo = this.$vc.getCurrentStaffInfo();
      this.assessmentType = +type;
      if (+type === ASSESSMENT_TYPE.COMMON) {
        this.getAssessmentTable();
      }
      if (+type === ASSESSMENT_TYPE.BUSINESS) {
        this.getBusinessAssessmentItem();
        if (Number(staffInfo.dutyType) === 1001) {
          this.initAssessmentObjectType();
        }
      }
    },
    queryData() {
      this.changeAssessmentType(this.assessmentType);
    },
    // 打开添加考核表
    openAddCommonTable(tableInfo) {
      console.log(tableInfo);
      let params = {
        tableInfo,
        target: this.assessmentObjectType,
      };
      this.$vc.emit(this.$route.path, "addCommonTable", "show", params);
    },
    // 打开添加/编辑通用考核项
    openCommonAssessmentItem(formInfo, info) {
      this.assessmentItemType = ASSESSMENT_ITEM_TYPE.COMMON_ITEM;
      let itemInfo = {
        orgId: this.queryParams.checkedOrg.code,
      };
      if (info) {
        itemInfo = info;
      }
      if (formInfo) {
        itemInfo.formId = formInfo.id;
      }
      this.$refs.editAssessmentItem.changeShowStatus(true, itemInfo);
    },
    // 打开添加/编辑业务考核项
    openBusinessAssessmentItem(info) {
      this.assessmentItemType = ASSESSMENT_ITEM_TYPE.BUSINESS_ITEM;
      let itemInfo = {
        orgId: this.queryParams.checkedOrg.code,
      };
      if (info) {
        itemInfo = info;
      }
      this.$refs.editAssessmentItem.changeShowStatus(true, itemInfo);
    },
    // 删除通用考核项
    deleteCommonItem(formInfo, item) {
      this.deleteType = DELETE_TYPE.COMMON_ITEM;
      this.deleteInfo = {
        itemId: item.id,
        formId: formInfo.id,
      };
      this.$refs.deleteModal.changeShowStatus(true);
    },
    // 删除业务考核项
    deleteBusinessItem(item) {
      this.deleteType = DELETE_TYPE.BUSINESS_ITEM;
      this.deleteInfo = {
        itemId: item.id,
      };
      this.$refs.deleteModal.changeShowStatus(true);
    },
    // 删除考核表
    deleteAssessmentTable(tableInfo) {
      this.deleteType = DELETE_TYPE.TABLE;
      this.deleteInfo = {
        formId: tableInfo.id,
        orgId: "",
      };
      this.orgList.forEach((val) => {
        if (val.orgName === tableInfo.orgName) {
          this.deleteInfo.orgId = val.orgId;
        }
      });
      this.$refs.deleteModal.changeShowStatus(true);
    },
    // 查看通用考核项
    lookCommonAssessmentItem(formInfo) {
      let items = {};
      for (let key in this.assessmentItemOpenStatus) {
        items[key] = this.assessmentItemOpenStatus[key];
      }
      items[formInfo.id] = !items[formInfo.id];
      this.assessmentItemOpenStatus = items;
    },
    // 获取考核表的数据
    getAssessmentTable(pageNo = 1, pageSize = 10) {
      this.$fly
          .post(listGeneralFormUrl, {
            dept: this.queryParams.checkedOrg.dutyType,
            orgId: this.queryParams.checkedOrg.code,
            target: this.assessmentObjectType,
            operatorId: this.$vc.getCurrentStaffInfo().id,
            pageNo,
            pageSize,
          })
          .then((res) => {
            this.assessmentTableData = res.data.datas;
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, 'pagination', 'init', {
                total: res.data.total || res.data.datas.length,
                currentPage: pageNo,
                pageSize: res.data.total ? pageSize : res.data.datas.length,
              })
            }
          });
    },
    // 获取业务考核项的数据
    getBusinessAssessmentItem(pageNo = this.pageParams.pageNo, pageSize = 10) {
      // this.pageParams.pageNo = pageNo;
      // this.pageParams.pageSize = pageSize;
      this.$fly
          .post(listProfessionalItemUrl, {
            dept: this.queryParams.checkedOrg.dutyType,
            orgId: this.queryParams.checkedOrg.code,
            target: this.assessmentObjectType,
            operatorId: this.$vc.getCurrentStaffInfo().id,
            pageNo,
            pageSize
          })
          .then((res) => {
            this.businessItemData = res.data.datas || res.data || [];
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, 'pagination', 'init', {
                total: res.data.total || res.data.length,
                currentPage: pageNo,
                pageSize: res.data.total ? pageSize : res.data.length,
              })
            }
          });
    },
  },
};
</script>
<style scoped lang="stylus">
.icon.reversal
  animation arrowRotate 0.1s linear 1
  transform rotate(180deg)
</style>
